<template>
  <div class="teachers-day">
    <div class="corner-left">
      <square src="https://img.xinshu.me/resource/3cee1a07c630474a8e797bbb5c2b9591" alt="" plain height="103.9%"/>
    </div>
    <div class="corner-right">
      <square src="https://img.xinshu.me/resource/7dd9b3deedbb435e96d45b839c2e5417" alt="" plain height="103.9%"/>
    </div>
    <template v-if="step === 1">
      <div class="page-1">
        <div class="title">
          <square src="https://img.xinshu.me/resource/2a4a7b8d12bd4920bb1eaed2d3fabbbd" alt="" plain height="38%"/>
        </div>
        <div class="word"><ImageText>“做张贺卡送老师”</ImageText></div>
        <div class="tch">
          <square src="https://img.xinshu.me/resource/3a3d3d9b4825491793db742b983e158d" alt="" plain height="103.1%"/>
        </div>
        <div class="btn-box">
          <b-btn class="tch-btn-long tch-btn-fill" variant="custom" pill @click="step = 2">开始制作</b-btn>
        </div>
      </div>
    </template>
    <template v-else-if="step === 2">
      <div class="page-2">
        <div class="content">
          <b-row>
            <b-col class="greet-card">
              <square class="pic-shadow" :src="previewCard" alt="" radius="0" plain height="152%"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="greet-styles">
                <b-row align-v="center" class="mb-3">
                  <b-col>
                    <h6 class="mb-1 text-darkColor">选择贺卡样式</h6>
                    <div class="small text-muted">
                      <span class="d-sm-none">左右滑动查看更多</span>
                    </div>
                  </b-col>
                  <b-col cols="auto">
                    <b-btn v-if="greetCard.editable" class="change-pic" variant="custom" pill @click="uploadPic">
                      <template v-if="uploading">
                        <fa class="icon" icon="spinner" spin/>
                        上传中
                      </template>
                      <template v-else>
                        上传图片
                      </template>
                    </b-btn>
                    <div v-else class="small text-lightColor">此贺卡不支持自定义图片</div>
                  </b-col>
                </b-row>

                <grid class="greet-list" scrollable>
                  <grid-item :key="item.id" v-for="item in cardsList">
                    <square :selected="item.id === greetCard.id" :src="item.bg"
                            @click="selectCard(item)" height="152%">
                      <div class="greet-tag" v-if="item.editable">
                        <fa icon="image"/>
                      </div>
                    </square>
                  </grid-item>
                </grid>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="btn-box">
          <b-btn class="tch-btn-long tch-btn-fill" variant="custom" pill @click="step = 3">下一步</b-btn>
        </div>
      </div>
    </template>
    <template v-else-if="step === 3">
      <div class="page-3">
        <div class="content">
          <b-row>
            <b-col class="greet-card">
              <square class="pic-shadow" :src="previewCard" alt="" radius="0" plain height="152%"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="greet-styles">
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b slot="label" class="text-darkColor">
                        选择对老师的祝福语
                        <small>（最多80字）</small>
                      </b>
                      <b-select class="tch-input" v-model="bless" @change="onBlessChange">
                        <option :value="item" v-for="(item, idx) in blessList" :key="idx">{{item}}</option>
                      </b-select>
                    </b-form-group>
                    <b-form-group v-show="bless === '自定义'">
                      <textarea class="tch-input" style="width: 100%;" rows="3"
                                placeholder="写点对老师的祝福..." maxlength="80"
                                v-autosize v-model.lazy="greetCard.text"
                      ></textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b slot="label" class="text-darkColor">
                        输入称呼
                        <small>（最多14字）</small>
                      </b>
                      <input class="form-control tch-input" maxlength="14"
                            placeholder="亲爱的老师" v-model.lazy="greetCard.to">
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <b slot="label" class="text-darkColor">
                        输入署名
                        <small>（最多14字）</small>
                      </b>
                      <input class="form-control tch-input" maxlength="14"
                            placeholder="一（1）班 心小鹿" v-model.lazy="greetCard.from">
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="btn-box">
          <b-btn class="tch-btn-short tch-btn-blank" variant="custom" pill @click="step = 2">返回上一步</b-btn>
          <b-btn class="tch-btn-short tch-btn-fill" variant="custom" pill @click="step = 4">下一步</b-btn>
        </div>
      </div>
    </template>
    <template v-if="step === 4">
      <div class="page-4">
        <div class="content">
          <b-row>
            <b-col class="greet-card">
              <square class="pic-shadow" :src="previewCard" alt="" radius="0" plain height="152%"/>
            </b-col>
          </b-row>
          <div class="tips"><fa icon="long-arrow-up"/>长按上方图片保存贺卡<fa icon="long-arrow-up"/></div>
          <div class="btns">
            <b-btn class="tch-btn-short tch-btn-blank" variant="custom" pill @click="cardPopup = true">保存贺卡</b-btn>
            <b-btn class="tch-btn-short tch-btn-fill" variant="custom" pill @click="makeBook">{{newuser ? '免费做书' : '5折做书'}}</b-btn>
          </div>
          <div class="recommend" @click="jumpArticle">
            <square src="https://img.xinshu.me/resource/d18b33588df74066905361b3607d5372" alt="" radius="0" plain height="34.3%"/>
          </div>
        </div>
        <popup v-if="cardPopup" guide="保存你的贺卡" :src="previewCard" @close="cardPopup = false"/>
      </div>
    </template>
  </div>
</template>

<script>
// import { configShare } from '@/modules/wechat'
import ImageText from '../../components/ImageText.vue'
import Upload from '@/models/upload'

export default {
  name: 'teachersDay2021',
  title: '教师节贺卡',
  components: {
    ImageText
  },
  data() {
    return {
      step: 1,
      cardsList: [
        {
          id: '009',
          bg: 'https://canvas.xinshu.me/generate/teacher.009',
          editable: true
        },
        {
          id: '010',
          bg: 'https://canvas.xinshu.me/generate/teacher.010',
          editable: true
        },
        {
          id: '001',
          bg: 'https://canvas.xinshu.me/generate/teacher.001',
          editable: false
        },
        {
          id: '002',
          bg: 'https://canvas.xinshu.me/generate/teacher.002',
          editable: false
        },
        {
          id: '003',
          bg: 'https://canvas.xinshu.me/generate/teacher.003',
          editable: false
        },
        {
          id: '004',
          bg: 'https://canvas.xinshu.me/generate/teacher.004',
          editable: false
        },
        {
          id: '005',
          bg: 'https://canvas.xinshu.me/generate/teacher.005',
          editable: false
        },
        {
          id: '006',
          bg: 'https://canvas.xinshu.me/generate/teacher.006',
          editable: false
        },
        {
          id: '007',
          bg: 'https://canvas.xinshu.me/generate/teacher.007',
          editable: false
        },
        {
          id: '008',
          bg: 'https://canvas.xinshu.me/generate/teacher.008',
          editable: false
        },
        {
          id: '011',
          bg: 'https://canvas.xinshu.me/generate/teacher.011',
          editable: false
        },
        {
          id: '012',
          bg: 'https://canvas.xinshu.me/generate/teacher.012',
          editable: false
        }
      ],
      greetCard: {
        id: '009',
        bg: 'https://canvas.xinshu.me/generate/teacher.009',
        editable: true
      },
      uploadModel: new Upload(),
      blessList: [
        '在迷失的黑夜里，您是夜空中最亮的星，照亮我前行。老师您辛苦啦！',
        '为您颁发五颗星🌟🌟🌟🌟🌟\n您是我心中的最佳老师，教师节快乐！',
        '祝您教师节快乐！年年岁岁“花”相似，岁岁年年人不同。\n你辛苦了!!谢谢您对我的培养！',
        '将来，无论我成为参天大树，还是低矮的灌木，我都将以生命的翠绿向您祝福。\n老师我爱你~',
        '九月的秋天，我们庆祝丰收，您在辛勤耕耘。九月的秋天，我们采摘果实，您在浇灌幼苗。谢谢您，老师，在这个属于您的节日里，祝您永远平安幸福。',
        '是您引领着我们走过那段青春的旅程，用岁月的剪刀裁出精彩片段，让驿动的心能在和风细雨中欣赏无限风光。谢谢您，祝您节日快乐',
        '阳光普照，园丁心坎春意浓；\n甘雨滋润，桃李枝头蓓蕾红。\n祝您节日愉快！您辛苦了！',
        '自定义'
      ],
      bless: '在迷失的黑夜里，您是夜空中最亮的星，照亮我前行。老师您辛苦啦！',
      cardPopup: false
    }
  },
  computed: {
    previewCard() {
      const obj = {}
      if (this.greetCard.editable && 'pic' in this.greetCard) {
        obj.pic = this.greetCard.pic
      }
      if ('to' in this.greetCard) {
        obj.to = this.greetCard.to
      }
      if ('from' in this.greetCard) {
        obj.from = this.greetCard.from
      }
      if ('text' in this.greetCard) {
        obj.text = this.greetCard.text
      }
      return this.greetCard.bg + this.serialize(obj)
    },
    uploading() {
      return this.uploadModel.uploading
    },
    newuser() {
      return !this.user.hasBook
    }
  },
  methods: {
    selectCard(card) {
      if (card.id === this.greetCard.id) {
        return
      }
      Object.assign(this.greetCard, card)
    },
    async uploadPic() {
      const result = await this.uploadModel.upload()
      if (result) {
        this.$set(this.greetCard, 'pic', result.url)
      }
    },
    onBlessChange() {
      if (this.bless !== '自定义') {
        this.$set(this.greetCard, 'text', this.bless)
      } else {
        this.$set(this.greetCard, 'text', '')
      }
    },
    jumpArticle() {
      window.open('https://weixinshu.com/u/KwPKYJ')
    },
    async makeBook() {
      if (this.newuser) {
        await this.$req.get('/api/user/activity_coupons/LBGPD1')
        this.$alert.success('优惠券领取成功')
      } else {
        this.$gtag.event('teachersday_discount')
        this.$router.push('/packages/VADAKM')
      }
    }
  }
}
</script>

<style lang="scss">
[data-page="teachersDay2021"] {
  main {
    padding-bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
$lightColor: #FF696A;
$darkColor: #E34243;

.teachers-day {
  max-width: 480px;
  height: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  @include bgi('https://img.xinshu.me/resource/651c45e5c97942d8848715e2aecd2506');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .corner-left {
    width: 25%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .corner-right {
    width: 25%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .btn-box {
    position: absolute;
    width: 100%;
    z-index: 100;
    left: 0;
    bottom: 0;
    padding: 1rem 0 1.5rem 0;
    display: flex;
    justify-content: space-evenly;
  }

  .tch-btn-long {
    height: 50px;
    width: 50%;
    font-size: 16px;
  }

  .tch-btn-short {
    height: 50px;
    width: 38%;
    font-size: 16px;
  }

  .tch-btn-fill {
    background-color: $lightColor;
    border: #fff 2px solid;
    color: #fff;
  }

  .tch-btn-blank {
    background-color: #fff;
    border: $lightColor 2px solid;
    color: $lightColor;
  }

  .pic-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
  }

  .text-lightColor {
    color: $lightColor;
  }

  .text-darkColor {
    color: $darkColor;
  }

  .tch-input {
    border: none;
    border-radius: 0;
  }

  .page-1 {
    width: 100%;

    .title {
      width: 75%;
      margin-top: 7rem;
      margin-left: auto;
      margin-right: auto;
    }

    .word {
      width: 100%;
      margin-top: 2rem;
      text-align: center;
      font-size: 24px;
    }

    .tch {
      width: 90%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  ::v-deep .square-image {
    object-position: top;
  }

  .greet-card {
    max-width: 60%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .page-2 {
    height: 100%;
    padding: 0 1rem;
    padding-bottom: 85px;

    .square.selected {
      border: 2px solid $lightColor;
    }

    .greet-styles {
      margin-top: 3rem;
      padding: 0 3px;

      .change-pic {
        width: 90px;
        background-color: $lightColor;
        color: #fff;
      }
    }

    .greet-list {

      .grid-item {
        min-width: 7.25em;

        .greet-tag {
          position: absolute;
          top: 8px;
          left: 0;
          width: 2.5em;
          line-height: 1.8;
          border-radius: 0 100px 100px 0;
          z-index: 0;
          background-color: $success;
          color: #fff;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

  .page-3 {
    height: 100%;
    padding: 0 1rem;
    padding-bottom: 85px;

    .greet-styles {
      margin-top: 3rem;
      padding: 0 3px;
    }
  }

  .page-4 {
    height: 100%;
    padding: 0 1rem;

    .tips {
      width: 100%;
      margin-top: 1.5rem;
      text-align: center;
      font-size: 16px;
      color: $darkColor;
    }

    .btns {
      width: calc(100% + 2rem);
      margin-left: -1rem;
      padding: 2rem 0;
      display: flex;
      justify-content: space-evenly;
    }

    .recommend {
      cursor: pointer;
    }
  }
}
</style>
